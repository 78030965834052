import logoImage from '@image/logo.png';
import { Link } from 'react-router-dom';
import './style.css'
const HeaderComponent = () => {
  return (
    <header className="header fixed-top">
      <div className="container-fluid max-w1600 header-nav">
        <nav className="nav-menupage navbar navbar-expand-md navbar-light  p-0">
          <div className="logo"> <Link to="/"><img className="img-logo" src={logoImage} alt="" /></Link> </div>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span> </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav ml-auto my-2 my-lg-0 navbar-nav-scroll" style={{ maxheight: "100vh" }}>
              <li className="nav-item"> <Link className="nav-link" to="/result">Home </Link> </li>
              <li className="nav-item"> <Link className="nav-link" to="/term">Terms and Policy</Link> </li>
              <li className="nav-item"> <Link className="nav-link" to="/contact">Contact us</Link> </li>
            </ul>
          </div>
        </nav>
      </div >
    </header >
  );
};

export default HeaderComponent;