import FooterComponent from "components/templates/footer"

const ContactComponent = (props) => {
  return (
    <>
      <main className="main-pageupload">
        <div className="container-lg">
          <section className="summary-resume box-inner">
            <section className="row clearfix">
              <div className="col-md-12">
                <div className="summary-resume-box mt-0">
                  <h1>Contact us</h1>
                  <p>For information, please contact contact@itoutsource.work</p>
                </div>
              </div>
            </section>
          </section>
        </div>
      </main>
      <FooterComponent className="text-center footer mt-5" />
    </>
  )
}

export default ContactComponent