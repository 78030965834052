import FooterComponent from "components/templates/footer";

const TermComponent = (props) => {
  return (
    <>
      <main className="main-pageupload">
        <div className="container-lg">
          <section className="summary-resume box-inner">
            <section className="row clearfix">
              <div className="col-md-12">
                <div className="summary-resume-box mt-0">
                  <h1>TERMS OF SERVICE AND PRIVACY POLICY</h1>
                  We are thankful for your visiting and you are very welcomed to our web
                  application, ResumeRating Web Application (the “App”). Before you proceed any
                  further with our App, it is advisable that you consider the followings
                  carefully as it may affect your legal rights and obligations.
                  <h3>1. Purpose</h3>
                  <p>
                    1.1 By accessing the App, you agree to be
                    bound by the following terms of service and privacy policy (“Terms”)
                    which are legally binding between you (“Users”) and ResumeRating
                    (“RR”). If you have any questions about the App or the Terms contained
                    herein, you can contact RR by email specified in the last section of
                    these Terms.
                  </p>
                  <p>
                    1.2 In using the App, we require that all Users must be either (i)
                    individuals above the age of 18 years old or (ii) minors given
                    verifiable permission by your parent or legal guardian.
                  </p>
                  <p>
                    1.3 If you do not agree with these Terms or you are under the age of
                    18 without permission from your parent or legal guardian, you should
                    stop using the App immediately. Any using behaviors performed by Users
                    will be regarded as your acceptance to the Terms.
                  </p>
                  <h3>2. About RR</h3>
                  <p>
                    2.1 RR operates a resume online application business designed for job
                    searching and job advertising.
                  </p>
                  <p>
                    2.2 To be able to access the App, you need to access the App and may need
                    to create your own account. When creating a new account, some personal
                    information will be required. Subject to your compliance with the
                    Terms set forth herein, we grant you a non-exclusive,
                    non-transferable, revocable, limited right to access, to use and to
                    display the visible text, graphics, voice and images thereon and to
                    view and to download such text, graphics and images.
                  </p>
                  <p>
                    2.3 To be able to use the App, you need to upload your resume to the App.
                    When uploading your resume, you agree that RR can use your uploaded resume
                    for further job searching or job offering processes. You agree that RR can call you to offer jobs to you.
                  </p>
                  <p>
                    2.4 Each time you access to the App, you agree to accept the Email and/or
                    system notification from RR. We will not be responsible for any inconvenience
                    arising therefrom.
                  </p>
                  <p>
                    2.5 You agree that we may provide you with advertisements and/or links
                    that allow you to access third party services, applications and/or
                    websites. You agree that we are allowed to integrate advertising or
                    commercial contents into our App.
                  </p>
                  <h3>3. Terms of Use</h3>
                  <p>
                    3.1 The App is available for your own personal use and entertainment.
                    You agree not to use the App for any commercial purpose or for any
                    illegal or unauthorized purpose. When you use the App you must comply
                    with all applicable laws, including the local laws in your country of
                    residence (together referred to as "Applicable Laws") and the Terms.
                    In particular, but without limitation, you agree not to:
                  </p>
                  <p className="item">
                    (a) Use the App in any unlawful manner or in a manner which promotes
                    or encourages illegal activity or take part in an act that would
                    constitute an illegal or criminal charge or commit an act that would
                    go against the laws; or
                  </p>
                  <p className="item">
                    (b) Attempt to gain unauthorized access to the App or any networks,
                    servers or computer systems connected to the App; or
                  </p>
                  <p className="item">
                    (c) Modify, adapt, translate or reverse engineer any part of the App
                    or re-format or frame any portion of the pages comprising the App,
                    save to the extent expressly permitted by these Terms or by law; or
                  </p>
                  <p className="item">
                    (d) Make use of the contents and services provided by RR other than
                    the User’s own private use, without RR’s consent.
                  </p>
                  <p className="item">
                    (e) Give out unlawful information such as deceptive information, any
                    information that may involve in national security, public safety or
                    terrorism, or any obscene variety in the form of words, images, and
                    the like that may be harmful via e-mail and other venues of
                    communication;
                  </p>
                  <p className="item">
                    (f) Use the App in order to conduct business activities including, but
                    not limited to hacking, publishing advertisements for making money,
                    peddling pornography and other disturbing contents.
                  </p>
                  <p>
                    3.2 Any account you open with us is personal to you and you are
                    prohibited from gifting, lending, transferring or otherwise permitting
                    any other person to access or use your account. Your account names,
                    user ID and other identifiers you adopt to be used within our App
                    remains our property and we can disable, reclaim and reuse them once
                    your account is terminated or deactivated for whatever reason by
                    either you or us.
                  </p>
                  <p>
                    3.3 You shall ensure the authenticity, legitimacy and effectiveness of
                    the information provided for registration. You must, at all times,
                    ensure that your information does not infringe or violate the rights
                    of any person or otherwise contravene any applicable laws or
                    regulations. Otherwise, RR owns the rights to immediately terminate
                    your account or recover the account, and you will be solely
                    responsible for all legal liabilities arising therefrom.
                  </p>
                  <p>
                    3.4 You are responsible for: (i) safeguarding any passwords used to
                    access your account, and (ii) all use within our App under your
                    account. You must promptly notify us if you know or suspect that your
                    password or account has been hacked.
                  </p>
                  <p>
                    3.5 RR may suspend or terminate your access to your account or any or
                    all of our services at our sole discretion, if you fail to comply with
                    any and all the Terms herein and especially our Terms of Use as
                    specified above. Where reasonably practicable, we will give you a
                    notice of any suspension or termination.
                  </p>
                  <p>
                    3.6 When you upload your resume to the App, you agree that RR can use your uploaded resume
                    for further job searching or job offering processes. You agree that RR can call you to offer jobs to you.
                  </p>
                  <h3>4. Intellectual Property</h3>
                  <p>
                    4.1 RR reserves any and all the proprietary and intellectual property
                    rights (currently known and/or future known) in all material contained
                    on, in, or available through the App including all information, data,
                    text, music, sound, photographs, graphics and video messages, the
                    selection and arrangement thereof, and all source code, software
                    compilations and other material, including any future updates,
                    upgrades and new versions (“Materials”) are owned by or licensed to RR
                    or its affiliates and/or its subsidiaries. All rights are exclusively
                    reserved. You cannot copy, edit, vary, reproduce, publish, display,
                    distribute, store, transmit, commercially exploit, disseminate in any
                    form whatsoever or use the Materials without RR’s express permission.
                  </p>
                  <p>
                    4.2 The trademarks, service marks, names, domain names, logos or other
                    distinctive brand features ("Trade Marks") contained on or in the App
                    are exclusively owned by RR or its affiliates and/or its subsidiaries
                    or third party partners of RR. You cannot use, copy, edit, vary,
                    reproduce, publish, display, distribute, store, transmit, commercially
                    exploit or disseminate the Trade Marks or in whatsoever way without
                    the prior written consent of RR or the relevant affiliates and
                    subsidiaries or the relevant third party partner of RR.
                  </p>
                  <h3>5. Transactions with Advertisers</h3>
                  <p>
                    You understand and agree that RR will not take any responsibility
                    arising from any losses or damages that may have occurred due to the
                    authorized or unauthorized advertisers’ taking part in promotional
                    activities in the App. RR will not be responsible for the practices
                    employed by any websites, applications or services linked to or from
                    the App, including the information or content contained within them.
                    Please be aware that if you choose to use a link to go from the App to
                    any third party's websites, applications or services, such third
                    party's own terms of service and privacy policy will apply and govern
                    your activities and any information you disclose while interacting
                    with such third parties.
                  </p>
                  <h3>6. Damages</h3>
                  <p>
                    6.1 You understand that the use of the App is solely at your own risk
                    and the App is provided on an "AS IS" basis. To the maximum extent
                    permitted by law: {' '}
                  </p>
                  <p className="item">
                    (a) RR disclaims all liabilities whatsoever, whether arising from or
                    related to any contract, tort (including negligence) or otherwise in
                    relation to the App; and
                  </p>
                  <p className="item">
                    (b) All implied warranties, terms and conditions relating to the App
                    (whether implied by the applicable or otherwise), including but
                    without limitation any warranty, term or condition as to accuracy,
                    completeness, satisfactory quality, performance, fitness for use or
                    any special purpose, availability, non-infringement, information
                    accuracy, interoperability, peaceful enjoyment and title are hereby
                    excluded.
                  </p>
                  <p>
                    6.2 In particular, RR will not be liable for any loss, injury or
                    damages, whether special, direct, indirect, incidental, or
                    consequential, including but not limited to technical failure of the
                    App, any damage or injury to users or their equipment as a result of
                    or relating to their use of the App, loss of revenue, opportunities,
                    profits or data, error, omission, interruption, delay in operation or
                    transmission, computer virus, malicious code, system failure, loss of
                    data, loss of use or other defect related to online services, even if
                    RR is aware of the possibility of such damages.
                  </p>
                  <p>
                    6.3 You agree to indemnify RR, its directors, its employees, its
                    affiliates and/or its subsidiaries in full from and against any loss,
                    damage, costs or expenses which they may suffer or incur directly or
                    indirectly as a result of your use of the App otherwise than in
                    accordance with these Terms or Applicable Laws
                  </p>
                  <h3>7. Privacy Policy</h3>
                  <p>
                    7.1 As we take your privacy very seriously, you can rest assured that
                    we are committed to protecting your privacy.
                  </p>
                  <p>
                    7.2 When you register for an account or access to the App, you should
                    always provide true identity when we request for your information for
                    registration and identification, such as your mobile phone number or
                    E-mail address. If you provide incomplete or fault information, RR
                    reserves the right to restrict or cease your use of the App. If you
                    wish to change, alter, suspend or non-disclose any of your information
                    provided to us, you may do so through our App at any time. Also, if
                    you do not wish to share your information with us anymore, you should
                    inform us by the below email and stop using this App immediately.
                  </p>
                  <p>
                    7.3 Please note that you should never disclose important information
                    such as any kinds of property owned, bank accounts, bank cards, credit
                    cards, third-party payment accounts and corresponding passwords and
                    other services when using the App. RR will not be responsible for any
                    loss resulting arising therefrom.
                  </p>
                  <p>
                    7.4 It is advisable that you must consider carefully what you post or
                    communicate on our App. If you publish, upload your personal
                    information on the App or transmit your personal information to other
                    Users by yourself resulting in leak of your personal information. You
                    will be solely responsible for all liabilities or damages arising
                    therefrom.
                  </p>
                  <p>
                    7.5 Any using behaviors performed by you within our App shall be
                    deemed as you give an implied consent that we may collect information
                    from you for RR’s further process.
                  </p>
                  <p>
                    7.6 You agree that the data collected by us shall be used in the
                    operations, maintenance and enhancement of its services and content,
                    and for other administrative purposes or internal operations, such as
                    communicating with our users, data analysis, testing and research.
                  </p>
                  <p>
                    7.7 Data collected may also be used to effectively deliver
                    advertisements based on our Users’ online activities, provide an
                    insight into what our Users are interested in, maximize the
                    effectiveness of advertisements and improve and enhance our services
                    and content.
                  </p>
                  <p>
                    7.8 RR may share the data with its affiliates, partners, and other
                    trusted business and individuals that provide RR, or to which RR
                    provides, with a variety of services, such as publishers and
                    advertisers that work with RR and service providers who process such
                    information for RR, in order to provide and improve our services. Such
                    third parties are using your information in compliance with our
                    Privacy Policy and any other appropriate confidentiality and security
                    measures, and by accessing the App, you acknowledge and agree that
                    these trusted third parties may have access to Data, to the extent it
                    is reasonably necessary for them in order to perform their tasks
                    pertaining to their engagement with RR.
                  </p>
                  <p>
                    7.9 RR may publish or share with third parties some information for
                    the purpose of analysis and statistics. Such disclosure is done
                    anonymously and is non-personally identifiable.
                  </p>
                  <p>
                    7.10 When you upload your resume to the App, you agree that RR can use
                    your uploaded resume for further job searching process. RR can telephone
                    you to offer jobs to you.
                  </p>
                  <h3>8. Security</h3>
                  <p>
                    8.1 All the information described above may be stored on database
                    servers for the period of no less than 90 days or as required by the
                    applicable law.
                  </p>
                  <p>
                    8.2 RR will take reasonable and appropriate organizational, physical
                    and technical measures intended for the protection of data against any
                    accidental or unlawful destruction, alteration and disclosure, as well
                    as against any other unlawful processing before it is transferred
                    across the internet from your system to our servers and in securing
                    such information stored on our systems. However, you should be aware
                    that there is always some risk involved in transmitting information
                    over the internet and that there is also some risk that others could
                    find a way to thwart our security systems. As a result, RR cannot 100%
                    ensure or warrant the security and privacy of data you transmit using
                    RR, and you do so at your own risk. Thus, RR strongly encourages you
                    to exercise discretion regarding the information you choose to
                    disclose.
                  </p>
                  <h3>9. Additional Terms</h3>
                  <p>
                    9.1 RR reserves the right to amend these Terms from time to time and
                    will post it on the App. The amendment thereof will be effective
                    immediately. These Terms shall constitute the entire agreement between
                    Users and RR concerning your use of the App. You are responsible for
                    regularly reviewing these Terms so that you are aware of any changes
                    to them and you will be bound by the new terms upon your continued
                    usage of the App.
                  </p>
                  <p>
                    9.2 If any provision(s) of these Terms is held by a court of competent
                    jurisdiction to be invalid or unenforceable, then such provision(s)
                    shall be construed, as nearly as possible, to reflect the intentions
                    of the parties (as reflected in the provision(s)) and all other
                    provisions shall remain in full force and effect. RR’s failure to
                    exercise or enforce any right or provision of these Terms shall not
                    constitute a waiver of such right or provision unless acknowledged and
                    agreed to by RR in writing.
                  </p>
                  <p>
                    9.3 Any dispute, controversy or claim (whether in contract, tort or
                    otherwise) arising out of, relating to, or in connection with these
                    Terms, including their existence, validity, interpretation,
                    performance, breach or termination, will be construed by the laws of
                    Thailand and referred to and finally resolved by the non-exclusive
                    court of Thailand.
                  </p>
                  <h3>CONTACT INFORMATION</h3>
                  <p>Email: contact@itoutsource.work</p>
                </div>
              </div>
            </section>
          </section>
        </div>
      </main>
      <FooterComponent className="text-center footer mt-5" />
    </>
  )
};

export default TermComponent;