import TermComponent from 'components/screens/pages/TermComponent';
import { Modal, Button } from 'react-bootstrap';

const TermConfirmModal = (props) => {
  const { onHide } = props;

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="col-md-9">
          Terms and Conditions
        </Modal.Title>
        <Button onClick={() => onHide(true)}>Agree</Button>
        <Button className="btn-secondary" style={{ marginRight: "10px" }} onClick={() => onHide(false)}>Cancel</Button>
      </Modal.Header>
      <Modal.Body>
        <TermComponent />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHide(true)}>Agree</Button>
        <Button className="btn-secondary" onClick={() => onHide(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>);
}

export default TermConfirmModal;
