import { Switch, Route, HashRouter } from "react-router-dom";
import ContactComponent from "components/screens/pages/ContactComponent";
import HeaderComponent from "components/templates/header";
import TermComponent from "components/screens/pages/TermComponent";
import UploadComponent from '../components/screens/pages/UploadComponent';
import UploadResultComponent from "../components/screens/pages/UploadResultComponent";
import { useContext } from "react";
import DataContext from "redux/DataContext";
import LoadingIndicator from "components/templates/LoadingIndicator";

const AppNavigator = (props) => {
  const context = useContext(DataContext);
  const {
    isFetching,
  } = context;

  return isFetching ? (
    <LoadingIndicator />
  ) : (
    <div className="wrapper">
      <HashRouter>
        <HeaderComponent />
        <Switch>
          <Route exact path="/">
            <UploadComponent />
          </Route>
          <Route path="/result">
            <UploadResultComponent />
          </Route>
          <Route path="/term">
            <TermComponent />
          </Route>
          <Route path="/contact">
            <ContactComponent />
          </Route>
        </Switch>
      </HashRouter>
    </div>
  );
};

export default AppNavigator;