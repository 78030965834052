import { API_BASE_URL, ACCESS_TOKEN } from '../constants'

const getJob = (positionId) => {
  const URL = API_BASE_URL + `/api/job/getByPositionId/${positionId}`
  const headers = new Headers({
    'Content-Type': 'application/json'
  })
  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      'Authorization', `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
    )
  }
  const options = {
    method: 'GET',
    headers,
  }
  return fetch(URL, options)
    .then(resp => resp.ok ? Promise.resolve(resp) : Promise.reject(new Error(resp.statusText)))
    .then(resp => resp.json());
}

const upload = (data) => {
  const URL = API_BASE_URL + '/api/file/upload'
  const headers = new Headers({
    // 'Content-Type': 'application/json'
  })
  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      'Authorization', `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
    )
  }
  const options = {
    method: 'POST',
    headers,
    body: data,
  }
  return fetch(URL, options)
    .then(resp => resp.ok ? Promise.resolve(resp) : Promise.reject(new Error(resp.statusText)))
    .then(resp => resp.json());
}

const setResumeScore = (resumeId, score) => {
  const URL = API_BASE_URL + `/api/resume/${resumeId}/${score}`
  const headers = new Headers({
    'Content-Type': 'application/json'
  })
  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append(
      'Authorization', `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
    )
  }
  const options = {
    method: 'POST',
    headers,
  }
  return fetch(URL, options)
    .then(resp => resp.ok ? Promise.resolve(resp) : Promise.reject(new Error(resp.statusText)))
    .then(resp => resp.json());
}

const API = {
  getJob,
  upload,
  setResumeScore,
}

export default API;
