// export const API_BASE_URL = 'https://fireworkai.com/resume_ai'
// export const API_BASE_URL = 'http://localhost:8080/resume-ai'
export const API_BASE_URL = '/resume-ai'
export const ACCESS_TOKEN = 'accessToken'

export const OAUTH2_REDIRECT_URI = '/oauth2/redirect'

export const GOOGLE_AUTH_URL =
  API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI
export const FACEBOOK_AUTH_URL =
  API_BASE_URL +
  '/oauth2/authorize/facebook?redirect_uri=' +
  OAUTH2_REDIRECT_URI
export const GITHUB_AUTH_URL =
  API_BASE_URL + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI
