import uploadImage1 from '@image/file-pdf.svg';
import uploadImage2 from '@image/logo-upload.png';
import uploadImage3 from '@image/upload.svg';
import TermConfirmModal from 'components/screens/modals/TermConfirmModal';
import FooterComponent from 'components/templates/footer';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import DataContext from 'redux/DataContext';
const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 900000;
const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);
const UploadComponent = (props) => {
  const context = useContext(DataContext);
  const {
    action,
    status,
    error,
    upload,
    agree,
    setAgree,
    selectedFile,
    setSelectedFile,
  } = context;

  const fileInputField = useRef(null);
  const [goToResultPage, setGoToResultPage] = useState(false);
  const [showTermModal, setShowTermModal] = useState(false);
  const [previewZoneClass, setPreviewZoneClass] = useState('preview-zone hidden');
  const [wrapperZoneClass, setWrapperZoneClass] = useState('dropzone-sec');
  const [boxZoneBody, setBoxZoneBody] = useState('');

  useEffect(() => {
    if (status === 'succeeded' && action === 'UPLOAD') {
      setGoToResultPage(true);
    } else {
      setGoToResultPage(false);
      if (status === 'failed' && action === 'UPLOAD') {
        alert(error);
        removeFile();
      }
    }
  }, [status, error, action]);

  const handleUploadBtnClick = (e) => {
    // if (!agree) {
    //   setShowTermModal(true);
    // }
    if (fileInputField.current) {
      fileInputField.current.click();
    }
  };

  const handleNewFileUpload = (e) => {
    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      if (inputFile.type !== 'application/pdf' || inputFile.size === 0 || inputFile.size > DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
        alert(`Please upload a pdf file. (max size=${convertBytesToKB(DEFAULT_MAX_FILE_SIZE_IN_BYTES)} kb)`);
        removeFile();
      } else {
        if (agree) {
          setWrapperZoneClass('dropzone-sec');
          setPreviewZoneClass('preview-zone');
          setBoxZoneBody(inputFile.name);
          uploadFile(inputFile);
        } else {
          setSelectedFile(inputFile);
          setShowTermModal(true);
        }
      }
    }
  };

  const removeFile = () => {
    setWrapperZoneClass('dropzone-sec');
    setPreviewZoneClass('preview-zone hidden');
    setBoxZoneBody('');
  };

  const uploadFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      fetch(e.target.result)
        .then(res => res.blob())
        .then(blob => {
          const formData = new FormData();
          formData.append('file', blob, file.name);
          upload(formData);
        })
    }
    reader.readAsDataURL(file);
  }

  if (goToResultPage) {
    return <Redirect to="/result" />;
  }

  return (
    <>
      <TermConfirmModal
        show={showTermModal}
        onHide={(result) => {
          if (result) {
            setAgree(result);
            setWrapperZoneClass('dropzone-sec');
            setPreviewZoneClass('preview-zone');
            setBoxZoneBody(selectedFile.name);
            uploadFile(selectedFile);
          }
          setShowTermModal(false);
        }} />
      <main className="main-upload-file">
        <div className="main-upload-fileinner d-flex align-items-start flex-column w-100">
          <div className="mb-auto w-100">
            <div className="container-fluid">
              <div className="upload-file-box">
                <header className="upload-file-title">
                  <h1>For <span className="text-warning">IT</span> professional, upload your <span className="text-warning">resume</span> and our <span className="text-warning">AI</span> will predict your suitable <span className="text-warning">salary</span></h1>
                </header>
                <div className="upload-file-body">
                  <form>
                    <div className="form-group w-100 m-0">
                      <div className="dropzone-wrapper">
                        <div className={previewZoneClass}>
                          <div className="clearfix">
                            <div className="box box-solid">
                              <figure><img className="img-svg" src={uploadImage1} height="50" alt="" /></figure>
                              <div className="box-body"><p>{boxZoneBody}</p></div>
                            </div>
                            <div className="progress mt-3" style={{ width: "100px", marginLeft: "auto", marginRight: "auto" }}>
                              <div className="progress-bar progress-bar-striped bg-warning progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: "100%" }}></div>
                            </div>
                          </div>
                        </div>
                        <div className={wrapperZoneClass}>
                          <input type="file" ref={fileInputField} accept=".pdf" className="dropzone" title="" value="" onChange={handleNewFileUpload} />
                          <div className="dropzone-desc w-100">
                            <figure><img src={uploadImage2} height="100" alt="" /></figure>
                            <p className="txt-drop">Drag and drop your resume .pdf here</p>
                            <button className="btn btn-uploadfront" onClick={handleUploadBtnClick}><i><img src={uploadImage3} height="20" alt="" /></i> Browse a file</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="text-black-50 text-center small mt-0 pt-2">By uploading your resume, I confirm I have read and accept ResumeRating’s <Link to="/term" className="font-italic">Terms and Privacy Poicy</Link>.</div>
                </div>
              </div>
            </div>
          </div>
          <FooterComponent className="text-center footer w-100" />
        </div >
      </main >
    </>
  );
}

export default UploadComponent