import { createContext } from "react"

export const initialState = {
  acction: null,
  status: 'idle',
  isFetching: false,
  error: null,
  result: {
    result: null,
    salary: null,
    position: null,
    positionId: null,
    yearOfExperience: null,
    startEducationYear: null,
    endEducationYear: null,
    startWorkYear: null,
    endWorkYear: null,
    phoneNo: null,
    email: null,
    wordCountTextFile: null,
  },
  jobs: [],
  upload: null,
  getJob: null,
  agree: true,
  setAgree: null,
  selectedFile: null,
  setSelectedFile: null,
  score: -1,
  setResumeScore: null,
}
const DataContext = createContext(initialState)
export default DataContext
