import uploadImage from '@image/upload.svg';
import logoJobDB from '@image/jobdb.png';
import logoJobTopGun from '@image/jobtopgun.png';
import logoJobThai from '@image/jobthai.png';
import logoJobBKK from '@image/jobbkk.png';
import DataContext from 'redux/DataContext';
import { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import FooterComponent from 'components/templates/footer';

const UploadSection = (props) => {
  const { action, status, error, upload } = props;

  useEffect(() => {
    if (status === 'succeeded' && action === 'UPLOAD') {
    } else {
      if (status === 'failed' && action === 'UPLOAD') {
        console.log(error);
      }
    }
  }, [status, error, action]);

  const readFile = (event) => {
    const input = event.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (e.target.result) {
          fetch(e.target.result)
            .then(res => res.blob())
            .then(blob => {
              const data = new FormData();
              data.append('file', blob, input.files[0].name);
              upload(data);
            })
        }
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  return (
    <section className="main-pageupload-search">
      <div className="row align-items-center">
        <div className="col-lg-5">
          <h2>Check your <span className="txt-yellow">resume</span> easily Just <span className="txt-yellow">upload</span> here</h2>
        </div>
        <div className="col-lg-7">
          <form className="form-resultupload" >
            <div className="input-group mb-3">
              <div className="custom-file" >
                <input type="file" className="custom-file-input" accept=".pdf" id="inputGroupFile02" onChange={readFile} />
                <label className="custom-file-label" htmlFor="inputGroupFile02">
                  <div className="container-fluid">
                    <div className="row align-items-center clearfix">
                      <div className="col-10 col-md p-0">
                        <div className="choose-files">Choose resume in PDF</div>
                      </div>
                      <div className="col-2 col-md-auto p-0">
                        <div className="btn btn-uploadfront"><i className="m-0"><img src={uploadImage} height="20" alt="" /></i> <span className="d-none d-lg-inline-block ml-2">Browse a file</span></div>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

const UploadSummarySection = (props) => {
  const {
    result: { resumeId, result, salary, position, yearOfExperience, startEducationYear, endEducationYear, startWorkYear, endWorkYear, phoneNo, email, },
    setResumeScore,
    score,
  } = props;

  const NotMatch = () => {
    return (
      <div className="resume-overview-list row">
        <div className="col-md-12">
          <div className="resume-items">
            <div className="row clearfix">
              <div className="col-12 blockquote">Position not match. For BETA version, our AI supports only <span className="txt-yellow">Java</span> programmer, <span className="txt-yellow">Android</span> programmer, <span className="txt-yellow">iOS</span> programmer, <span className="txt-yellow">PHP</span> programmer, <span className="txt-yellow">.NET</span> programmer and Software <span className="txt-yellow">Tester</span> only.</div>
            </div>
          </div>
        </div>
      </div >
    );
  }

  const ChooseScore = () => {
    return (
      <div className="col-md-6">
        <div className="resume-items">
          <div className="row clearfix">
            <div className="col-2" style={{ cursor: "pointer" }} onClick={() => { setResumeScore(resumeId, 9) }}><i className="fa fa-thumbs-up text-warning" aria-hidden="true">Agree</i></div>
            <div className="col-2" style={{ cursor: "pointer" }} onClick={() => { setResumeScore(resumeId, 0) }}><i className="fa fa-thumbs-down text-secondary" aria-hidden="true">Disagree</i></div>
          </div>
        </div>
      </div>
    );
  }

  const Summary = () => {
    return (
      <>
        <div className="resume-overview-list row">
          <div className="col-md-6">
            <div className="resume-items">
              <div className="row clearfix ">
                <div className="resume-info blockquote font-weight-bold text-left">Salary: </div>
                <div className="resume-info"><span className="blockquote font-weight-bold text-danger">{Number(salary).toLocaleString()}</span> THB/Month</div>
              </div>
            </div>
          </div>
          {(score === -1) && <ChooseScore />}
        </div>
        <div className="resume-overview-list row">
          <div className="col-md-6">
            <div className="resume-items">
              <div className="row clearfix">
                <div className="resume-label">Position: </div>
                <div className="resume-info">{position}</div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="resume-items">
              <div className="row clearfix">
                <div className="resume-label">Years of experiences: </div>
                <div className="resume-info">{yearOfExperience}</div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="resume-items">
              <div className="row clearfix">
                <div className="resume-label">Education Year: </div>
                <div className="resume-info">{startEducationYear && startEducationYear !== 9999 ? startEducationYear + "-" + endEducationYear : "-"}</div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="resume-items">
              <div className="row clearfix">
                <div className="resume-label">Work Year:</div>
                <div className="resume-info">{startWorkYear && startWorkYear !== 9999 ? startWorkYear + "-" + endWorkYear : "-"}</div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="resume-items">
              <div className="row clearfix">
                <div className="resume-label">Tel: </div>
                <div className="resume-info">{phoneNo === 'not found' ? '-' : phoneNo}</div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="resume-items">
              <div className="row clearfix">
                <div className="resume-label">Email: </div>
                <div className="resume-info">{email === 'not found' ? '-' : email}</div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-6">
                <div className="resume-items">
                  <div className="row clearfix">
                    <div className="resume-label">wordCount: </div>
                    <div className="resume-info"><a href={wordCountTextFile} target="_blank" rel="noreferrer">Link</a></div>
                  </div>
                </div>
              </div> */}
          <div className="col-md-12">
            <div className="resume-items">
              <div className="row clearfix">
                <div className="resume-label">Note: </div>
                <div className="mb-2">{result}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <section className="summary-resume box-inner">
      <div className="row clearfix">
        <div className="col-md-12">
          <div className="summary-resume-box mt-0">
            <h4>AI Analysis</h4>
            {position === 'None' ? <NotMatch /> : <Summary />}
          </div>
        </div>
        {/* <div className="col-md-6">
          <div className="summary-resume-box">
            <h5>Result</h5>
            <ul className="result-list">
              <li><i className="fa fa-check greenicon"></i> Good portfolio</li>
              <li><i className="fa fa-check greenicon"></i> Good exprience</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="summary-resume-box">
            <h5>Con.</h5>
            <ul className="result-list">
              <li><i className="fa fa-times redicon"></i> Lack of CV</li>
              <li><i className="fa fa-times redicon"></i> No refference</li>
            </ul>
          </div>
        </div> */}
      </div >
    </section >
  );
};

const TabItem = (props) => {
  const { tabId, tabName, tabSize, activeTab } = props;
  const tabItemClass = (tabId === activeTab) ? "nav-link active" : "nav-link";
  return (
    <li className="nav-item" role="presentation">
      <a className={tabItemClass} id={tabId + '-tab'} data-toggle="tab" href={'#' + tabId} role="tab" aria-controls={tabId} aria-selected={(tabId === activeTab)}>
        {tabName}
        <div className="label-jobs"><span>{tabSize}</span> JOBS</div>
      </a>
    </li>
  );
}

const TabList = (props) => {
  const { jobListGroupByKey, activeTab } = props;
  return (
    <div className="job-result-navtab">
      <ul className="nav nav-justified" id="myTab" role="tablist">
        {Object.keys(jobListGroupByKey).map(key => <TabItem key={key} tabId={key} tabName={key} tabSize={jobListGroupByKey[key].length} activeTab={activeTab} />)}
      </ul>
    </div>
  );
};

const TabPanel = (props) => {
  const { tabId, label, jobList, activeTab } = props;
  const crawledSources = ["JobsDB", "JOBTOPGUN", "Jobthai", "JobBKK"];
  const applyJob = (job) => {
    const { crawledSource, text1: detailUrl } = job;
    window.open(
      detailUrl,
      crawledSource
    );
  }

  const tabPanelClass = tabId === activeTab ? "tab-pane fade active show" : "tab-pane fade";
  return (
    <div className={tabPanelClass} id={tabId} role="tabpanel" aria-labelledby={label}>
      <div className="data-job-result">
        <div className="table-responsive">
          <table className="table mb-0">
            <tbody>
              {
                jobList.map((job, i) => {
                  const { id, crawledSource, companyName, jobPosition, text1: detailUrl, text2: logo, jobSalaryRange } = job;
                  const logoJob = crawledSource === crawledSources[0] ? logoJobDB :
                    crawledSource === crawledSources[1] ? logoJobTopGun :
                      crawledSource === crawledSources[2] ? logoJobThai :
                        crawledSource === crawledSources[3] ? logoJobBKK : null;
                  return (
                    <tr className="d-flex" key={id}>
                      <td className="col-1 data-img logo-job"><a href={detailUrl} target={crawledSource} rel="noreferrer"><img src={logoJob} alt="" /></a></td>
                      <td className="col-2 data-img text-center"><a href={detailUrl} target={crawledSource} rel="noreferrer"><img src={logo} alt="" /></a></td>
                      <td className="col-3">
                        <div className="label-orange">Job Company</div>
                        <a className="medium" href={detailUrl} target={crawledSource} rel="noreferrer">{companyName}</a>
                      </td>
                      <td className="col-3">
                        <div className="label-orange">Job tittle</div>
                        <a className="medium" href={detailUrl} target={crawledSource} rel="noreferrer">{jobPosition}</a>
                      </td>
                      <td className="col-1">
                        <div className="label-orange">Salary</div>
                        <a className="medium" href={detailUrl} target={crawledSource} rel="noreferrer">{jobSalaryRange}</a>
                      </td>
                      <td className="col-1 text-right"><button className="btn-line" onClick={() => applyJob(job)}>Apply</button></td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const TabContent = (props) => {
  const { jobListGroupByKey, activeTab } = props;
  return (
    <div className="tab-content" id="myTabContent">
      {Object.keys(jobListGroupByKey).map(key => <TabPanel key={key} tabId={key} label={key + 'tab'} jobList={jobListGroupByKey[key]} activeTab={activeTab} />)}
    </div>
  );
};

const JobResultTab = (props) => {
  const { jobs } = props;
  const jobListGroupByKey = jobs.reduce(
    (list, item) => {
      list[item['crawledSource']] = list[item['crawledSource']] || [];
      list[item['crawledSource']].push(item);
      return list;
    }, Object.create(null)
  );
  const [activeTab, setActiveTab] = useState(Object.keys(jobListGroupByKey)[0]);
  const updatedProps = { ...props, jobListGroupByKey, activeTab, setActiveTab };

  return (
    <div className="job-result-tab">
      <TabList {...updatedProps} />
      <div className="job-result-box">
        <TabContent {...updatedProps} />
      </div>
    </div>
  );
};

const JobResultSection = (props) => {
  const { action, status, error, result: { positionId }, clearJob, getJob, jobs, } = props;
  const [showJobs, setShowJobs] = useState(false);

  useEffect(() => {
    console.log(status, action);
    if (status === 'succeeded' && action === 'UPLOAD') {
      if (positionId === null) {
        clearJob();
        setShowJobs(true);
      } else {
        setShowJobs(false);
        getJob(positionId);
      }
    } else if (status === 'succeeded' && action === 'GET_JOB') {
      setShowJobs(true);
    } else {
      if (status === 'failed' && action === 'UPLOAD') {
        console.log(error);
      }
    }
  }, [status, error, action, positionId, clearJob, getJob]);

  // const applyAll = (jobs) => {
  //   if (jobs.length > 0) {
  //     const updatedJobs = [...jobs];
  //     const {jobsiteId, crawledSource} = updatedJobs.pop();

  //     let winObj;
  //     if (crawledSource === 'JobDB') {
  //       winObj = window.open(
  //         `https://th.jobsdb.com/TH/th/Job/SelectCoverLetterAndResume?jobAdIdList=${jobsiteId}&-opener-origin=${encodeURIComponent(window.location.origin)}`,
  //         crawledSource
  //       )
  //     }
  //     let loop = setInterval(() => {
  //       if (winObj === null || winObj.closed) {
  //         clearInterval(loop);
  //         if (updatedJobs.length > 0) {
  //           applyAll(updatedJobs);
  //         } else {
  //           alert('Finish');
  //         }
  //       }
  //     }, 1000);
  //   }
  // }

  // if (!showJobs) return <LoadingIndicator />;

  if (!showJobs) return (
    <section className="job-result ">
      <div className="job-result-top">
        <div className="row clearfix">
          <div className="col-sm">
            <h2>Loading...</h2>
          </div>
        </div>
      </div>
    </section>
  );

  if (jobs.length === 0) return (
    <section className="job-result ">
      <div className="job-result-top">
        <div className="row clearfix">
          <div className="col-sm">
            <h2>No Job Found</h2>
          </div>
        </div>
      </div>
    </section>
  );

  return (
    <section className="job-result ">
      <div className="job-result-top">
        <div className="row clearfix">
          <div className="col-sm">
            <h2>Matched Jobs</h2>
            <span> {jobs.length} jobs</span>
          </div>
          <div className="col-sm-auto">
            {/* <button className="btn-hover gradient" onClick={() => applyAll(jobs)}>Apply to all</button> */}
          </div>
        </div>
      </div>
      <JobResultTab {...props} />
    </section>
  );
};

const UploadResultComponent = (props) => {
  const context = useContext(DataContext);
  const { result: { result }, } = context;

  if (result === null) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <main className="main-pageupload">
        <div className="container-lg">
          <UploadSection {...context} />
          <UploadSummarySection  {...context} />
          <JobResultSection  {...context} />
        </div>
      </main>
      <FooterComponent className="text-center footer mt-5" />
    </>
  );
};

export default UploadResultComponent