import { initialState } from "./DataContext";

const createActionSet = name => ({
  RESET: `${name}_RESET`,
  PENDING: `${name}_PENDING`,
  SUCCESS: `${name}_SUCCESS`,
  FAILED: `${name}_FAILED`
})

export const SET_FILE = 'SET_FILE';
export const SET_AGREE = 'SET_AGREE';
export const UPLOAD = createActionSet('UPLOAD');
export const GET_JOB = createActionSet('GET_JOB');
export const APPLY_JOB = createActionSet('APPLY_JOB');
export const SET_SCORE = createActionSet('SET_SCORE');

export const reducer = (state, action) => {
  const updatedState = { ...state };
  switch (action.type) {
    case SET_FILE:
      updatedState.selectedFile = action.payload
      return updatedState;
    case SET_AGREE:
      updatedState.agree = action.payload
      return updatedState;
    case UPLOAD.RESET:
      updatedState.action = 'UPLOAD';
      updatedState.isFetching = false;
      updatedState.status = 'idle';
      updatedState.result = initialState.result;
      updatedState.error = null;
      return updatedState;
    case UPLOAD.PENDING:
      updatedState.action = 'UPLOAD';
      updatedState.isFetching = false;
      updatedState.status = 'loading';
      updatedState.error = null;
      return updatedState;
    case UPLOAD.SUCCESS:
      updatedState.action = 'UPLOAD';
      updatedState.isFetching = false;
      updatedState.status = 'succeeded';
      updatedState.result = action.payload;
      updatedState.error = null;
      return updatedState;
    case UPLOAD.FAILED:
      updatedState.action = 'UPLOAD';
      updatedState.isFetching = false;
      updatedState.status = 'failed';
      updatedState.error = action.error;
      return updatedState
    case GET_JOB.RESET:
      updatedState.action = 'GET_JOB';
      updatedState.isFetching = false;
      updatedState.status = 'idle';
      updatedState.jobs = initialState.jobs;
      updatedState.error = null;
      return updatedState;
    case GET_JOB.PENDING:
      updatedState.action = 'GET_JOB';
      updatedState.isFetching = false;
      updatedState.status = 'loading';
      updatedState.error = null;
      return updatedState;
    case GET_JOB.SUCCESS:
      updatedState.action = 'GET_JOB';
      updatedState.isFetching = false;
      updatedState.status = 'succeeded';
      updatedState.jobs = action.payload;
      updatedState.error = null;
      return updatedState;
    case GET_JOB.FAILED:
      updatedState.action = 'GET_JOB';
      updatedState.isFetching = false;
      updatedState.status = 'failed';
      updatedState.error = action.error;
      return updatedState;
    case SET_SCORE.RESET:
      updatedState.action = 'SET_SCORE';
      updatedState.isFetching = false;
      updatedState.status = 'idle';
      updatedState.error = null;
      return updatedState;
    case SET_SCORE.PENDING:
      updatedState.action = 'SET_SCORE';
      updatedState.isFetching = false;
      updatedState.status = 'loading';
      updatedState.error = null;
      return updatedState;
    case SET_SCORE.SUCCESS:
      updatedState.action = 'SET_SCORE';
      updatedState.isFetching = false;
      updatedState.status = 'succeeded';
      updatedState.score = action.payload;
      updatedState.error = null;
      return updatedState;
    case SET_SCORE.FAILED:
      updatedState.action = 'SET_SCORE';
      updatedState.isFetching = false;
      updatedState.status = 'failed';
      updatedState.error = action.error;
      return updatedState;
    default:
  }
}
