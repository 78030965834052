import React from 'react'
import './style.css'

const LoadingIndicator = () => {
  return (
    <div className="loading-container">
      <div className="spinner-border" style={{ width: "3rem", height: "3rem" }} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export default LoadingIndicator
