import GlobalState from 'redux/GlobalState';
import AppNavigator from 'routes/AppNavigator';
import './App.css';

const App = (props) => {
  return (
    <GlobalState>
      <AppNavigator />
    </GlobalState>
  );
}

export default App;
