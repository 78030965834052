import { useReducer } from 'react'
import API from '../api'
import DataContext, { initialState } from './DataContext'
import { GET_JOB, reducer, SET_AGREE, SET_FILE, SET_SCORE, UPLOAD } from './reducers'

const GlobalState = props => {
  const clearJob = () => {
    dispatch({
      type: GET_JOB.RESET
    })
  }

  const getJob = (positionId) => {
    dispatch({
      type: GET_JOB.PENDING
    })

    API.getJob(positionId).then(resp => {
      console.log('getJob success', resp);
      dispatch({
        type: GET_JOB.SUCCESS,
        payload: resp
      });
    }).catch(err => {
      console.log('getJob failed', err);
      dispatch({
        type: GET_JOB.FAILED,
        error: err
      });
    });
  }

  const upload = (data) => {
    dispatch({
      type: UPLOAD.PENDING
    });

    API.upload(data).then(resp => {
      if (resp.result) {
        console.log('upload success', resp);
        dispatch({
          type: SET_SCORE.SUCCESS,
          payload: -1
        });
        dispatch({
          type: UPLOAD.SUCCESS,
          payload: resp
        });
      } else {
        console.log('upload failed', resp);
        dispatch({
          type: UPLOAD.FAILED,
          error: resp
        });
      }
    }).catch(err => {
      console.log('upload failed', err);
      if (err.toString() === 'TypeError: Failed to fetch') {
        dispatch({
          type: UPLOAD.FAILED,
          error: 'upload failed'
        });
      } else {
        dispatch({
          type: UPLOAD.FAILED,
          error: err
        });
      }
    });
  }

  const setResumeScore = (resumeId, score) => {
    dispatch({
      type: SET_SCORE.PENDING
    })

    API.setResumeScore(resumeId, score).then(resp => {
      console.log('setResumeScore success', resp);
      dispatch({
        type: SET_SCORE.SUCCESS,
        payload: score
      });
    }).catch(err => {
      console.log('setResumeScore failed', err);
      dispatch({
        type: SET_SCORE.FAILED,
        error: err
      });
    });
  }


  const setAgree = (agree) => {
    dispatch({
      type: SET_AGREE,
      payload: agree
    });
  }

  const setSelectedFile = (agree) => {
    dispatch({
      type: SET_FILE,
      payload: agree
    });
  }

  const [state, dispatch] = useReducer(reducer, { ...initialState, upload, clearJob, getJob, setAgree, setSelectedFile, setResumeScore });

  return (
    <DataContext.Provider value={state}>
      {props.children}
    </DataContext.Provider>
  )
}

export default GlobalState;